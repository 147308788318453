import styled from 'styled-components';

const ContactUsStyles = styled.div`
  .ContactUs__container,
  .memberInfo__container {
    margin: 30px;
  }

  .memberInfo__message {
    max-width: 811px;
  }

  h3 {
    font-size: 16px;
    line-height: 200%;
    margin: 0 auto;
    max-width: 450px;
    padding-bottom: 30px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 450px;
  }

  .inputStyle {
    background: ${props => props.theme.white};
    border-radius: 3px;
    border: 1px solid #868686;
    box-sizing: border-box;
    height: 40px;
    margin-bottom: 20px;
    max-width: 450px;
  }

  input[type='text'],
  input[type='tel'],
  input[type='email'],
  input[type='number'],
  textarea[type='text'],
  input[type='date'],
  input[type='time'] {
    font-size: 16px;
    line-height: 200%;
  }

  .Input--message {
    height: 150px;
  }

  .Map__container {
    display: flex;
    flex-direction: row;
    height: 550px;
    justify-content: flex-start;
    text-align: center;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      height: auto;
    }
  }

  .Contact__group {
    padding-bottom: 20px;
  }

  .Contact__container {
    background: ${props => props.theme.orange};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 132px 145px;

    @media (max-width: 768px) {
      padding: 30px;
    }
  }

  .Contact__header,
  .Contact_subHeader {
    color: ${props => props.theme.white};
    font-size: 18px;
    font-style: bold;
    font-weight: 800;
    line-height: 25px;
    text-align: center;
  }

  .JoinNow__button {
    align-items: center;
    background: ${props => props.theme.orange};
    border-radius: 3px;
    border: 3px solid ${props => props.theme.orange};
    box-sizing: border-box;
    color: ${props => props.theme.white};
    display: flex;
    font-size: 14px;
    font-weight: 800;
    height: 45px;
    justify-content: center;
    line-height: 19px;
    margin-bottom: 115px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.2s ease-in-out;
    width: 174px;

    &:hover,
    &:active {
      background: transparent;
      color: ${props => props.theme.orange};
    }
  }
`;

export default ContactUsStyles;
