import React from 'react';
import { navigate } from 'gatsby-link';
// import ReCAPTCHA from 'react-google-recaptcha';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/styles/title';
import ContactUsStyles from '../components/styles/contact-us-styles';

function encode(data) {
  return (
    Object.keys(data)
      // eslint-disable-next-line prefer-template
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  );
}

const MemberInfoUpdate = () => {
  const [state, setState] = React.useState({});

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    // eslint-disable-next-line no-undef
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
      });
  };

  return (
    <Layout>
      <SEO title="Member Info Update" />
      <Title>Member Info Update</Title>

      <ContactUsStyles>
        <div className="memberInfo__container">
          <h3 className="memberInfo__message">
            Please fill out the form with current information regarding your
            stores. To change payment method (credit card, EFT, or check) please
            call the IOLCF office
            <a
              className="Contact__subHeader"
              href="tel:248-377-1900"
              style={{ textDecoration: 'none' }}
            >
              (248) 377-1900.
            </a>
          </h3>

          <form
            name="member-info-update"
            method="post"
            action="/thanks"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            {/* The `form-name` hidden field is required to 
            support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="member-info-update" />
            <p hidden>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                Don’t fill this out:
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <span>Franchise Number</span>
            <input
              className="inputStyle"
              name="Franchise Number"
              onChange={handleChange}
              placeholder="Franchise Number"
              required
              type="text"
            />
            <span>Company Name</span>
            <input
              className="inputStyle"
              name="Company Name"
              onChange={handleChange}
              placeholder="Company Name"
              required
              type="text"
            />
            <span>Email</span>
            <input
              className="inputStyle"
              name="Email"
              placeholder="Email"
              onChange={handleChange}
              required
              type="email"
            />
            <span>Main Contact</span>
            <input
              className="inputStyle"
              name="Main Contact"
              placeholder="Main Contact"
              onChange={handleChange}
              required
              type="text"
            />
            <span>Office Phone Number</span>
            <input
              className="inputStyle"
              name="Office Phone Number"
              placeholder="Office Phone #"
              onChange={handleChange}
              required
              type="tel"
            />
            <span>Cell Phone Number</span>
            <input
              className="inputStyle"
              name="Cell Phone Number"
              placeholder="Cell Phone Number"
              onChange={handleChange}
              required
              type="tel"
            />
            <span>Address (street, state, zip)</span>
            <textarea
              name="Address (street, state, zip)"
              className="inputStyle Input--message"
              onChange={handleChange}
              placeholder="Address (street, state, zip)"
              required
              type="text"
            />
            <span>Number of Stores</span>
            <input
              className="inputStyle"
              name="Number of Stores"
              placeholder="# of Stores"
              onChange={handleChange}
              required
              type="number"
            />

            {/* <ReCAPTCHA sitekey="YOUR_SITE_KEY" /> */}
            <button className="JoinNow__button" required type="submit">
              submit
            </button>
          </form>
        </div>
      </ContactUsStyles>
    </Layout>
  );
};

export default MemberInfoUpdate;
